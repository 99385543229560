<template>
    <el-dialog
        :title="title"
        :visible.sync="isShow"
        width="400px"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <el-form
            ref="userInfo"
            :model="form"
            label-width="80px"
            size="mini"
            :rules="rules"
            :key="key"
        >
            <el-form-item label="账号" prop="username">
                <el-input v-if="isAdd" v-model="form.username"></el-input>
                <span v-else>{{ form.username || "-" }}</span>
            </el-form-item>
            <el-form-item v-if="isAdd" label="密码" prop="password">
                <el-input v-model="form.password" type="password"></el-input>
            </el-form-item>
            <el-form-item v-if="isAdd" label="确认密码" prop="passwordConfirm">
                <el-input
                    v-model="form.passwordConfirm"
                    type="password"
                ></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.email"
                ></el-input>
                <span v-else>{{ form.email || "-" }}</span>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
                <el-input
                    v-if="isAdd || isEdit"
                    v-model="form.phone"
                ></el-input>
                <span v-else>{{ form.phone || "-" }}</span>
            </el-form-item>
            <el-form-item label="角色" v-if="!isAdd && !isEdit">
                <el-tag class="role-tag" v-for="role in roles" :key="role.id">{{
                    role.role_name
                }}</el-tag>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" v-if="isAdd || isEdit" @click="handlerReset"
                >重置</el-button
            >
            <el-button
                type="primary"
                size="mini"
                v-if="isAdd || isEdit"
                @click="handlerSave"
                >保存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import {
    addUserApi,
    updateUserApi,
    getUserRolesApi
} from "@/api/system/user.js";

export default {
    name: "UserDialog",
    components: {},
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String
        },
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Object
        }
    },
    computed: {
        isShow: {
            get: function() {
                return this.visible;
            },
            set: function(v) {
                this.$emit("update:visible", v);
            }
        },
        isAdd() {
            return this.type === "add";
        },
        isEdit() {
            return this.type === "update";
        },
        rules() {
            let {
                username,
                password,
                passwordConfirm,
                email,
                phone
            } = this.rulesAll;
            let rule = {};
            if (this.isAdd) {
                rule = Object.assign(rule, {
                    username,
                    password,
                    passwordConfirm,
                    email,
                    phone
                });
            } else if (this.isEdit) {
                rule = Object.assign(rule, {
                    email,
                    phone
                });
            } else {
                rule = {};
            }
            return rule;
        },
        reqParams() {
            let res = Object.assign({}, this.form);
            delete res.passwordConfirm;
            return res;
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        var validatePhone = (rule, value, callback) => {
            let reg = /^(\+86-)?1[3-9]\d{9}$/;
            if (value && !reg.test(value)) {
                callback(new Error("请输入正确的手机号码"));
            } else {
                callback();
            }
        };
        return {
            key: 0,
            form: {},
            rulesAll: {
                username: [
                    {
                        required: true,
                        message: "请输入账号",
                        trigger: "blur"
                    },
                    {
                        min: 3,
                        max: 50,
                        message: "长度在 3 到 50 个字符",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    }
                ],
                passwordConfirm: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    },
                    { validator: validatePass, trigger: "blur" }
                ],
                email: [
                    {
                        required: true,
                        message: "请输入邮箱",
                        trigger: "blur"
                    },
                    {
                        max: 50,
                        message: "长度在 50 个字符以内",
                        trigger: "blur"
                    },
                    {
                        type: "email",
                        message: "请输入正确的邮箱地址",
                        trigger: ["blur", "change"]
                    }
                ],
                phone: [
                    { validator: validatePhone, trigger: ["blur", "change"] }
                ]
            },
            roles: []
        };
    },
    methods: {
        async getUserRoles() {
            return new Promise((resolve, reject) => {
                getUserRolesApi(this.data)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "roles", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        setData() {
            this.$set(this, "roles", []);
            if (this.isAdd) {
                this.$set(this, "form", {
                    username: "",
                    password: "",
                    passwordConfirm: "",
                    email: "",
                    phone: ""
                });
            } else {
                this.$set(this, "form", Object.assign({}, this.data));
                if (!this.isEdit) {
                    this.getUserRoles();
                }
            }
        },
        handlerReset() {
            this.$refs["userInfo"].resetFields();
        },
        handlerOpen() {
            this.key++;
            this.setData();
        },
        handlerClose() {
            this.isShow = false;
        },
        handlerSave() {
            this.$refs["userInfo"].validate(valid => {
                if (valid) {
                    let action = undefined;
                    if (this.isAdd) {
                        action = addUserApi;
                    } else if (this.isEdit) {
                        action = updateUserApi;
                    } else {
                        return false;
                    }
                    action(this.reqParams)
                        .then(res => {
                            if (res.data.code == 0) {
                                this.$notify({
                                    title: "成功",
                                    message: "保存成功",
                                    type: "success"
                                });
                                this.handlerClose();
                                this.$emit("reload");
                            } else {
                                this.$notify.error({
                                    title: "失败",
                                    message: res.data.msg || "保存失败"
                                });
                            }
                        })
                        .catch(error => {
                            this.$notify.error({
                                title: "异常",
                                message: error
                            });
                        });
                } else {
                    return false;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.role-tag + .role-tag {
    margin-left: 10px;
}
</style>
